import React, { useContext, useRef } from "react";

import AppContext from "./context/AppContext";
import styles from "./styles/Form.module.css";
import Header from "./components/Header";

export default function Form({ match }) {
  const form = useRef(null);
  const { id } = match.params;
  const appContext = useContext(AppContext);

  let issue = {};
  if (id && !isNaN(id) && id <= appContext.sheet?.length) {
    issue = appContext.sheet[id - 1] || {};
  }

  return (
    <div className="container">
      <Header />
      <main>
        <h1 className="title">{issue.question}</h1>
        <h3 className={styles.description}>{issue.description}</h3>
      </main>
      <div style={{ width: "100%" }}>
        {issue.form && (
          <div className={styles.form}>
            <iframe
              ref={form}
              src={issue.form}
              height="800"
              width="100%"
              frameBorder="0"
              marginHeight="0"
              marginWidth="0"
              title="form"
            >
              Loading
            </iframe>
          </div>
        )}
      </div>
    </div>
  );
}
