import { useContext } from "react";
import { Link } from "react-router-dom";

import Header from "./components/Header";
import Loading from "./components/Loading";
import AppContext from "./context/AppContext";
import styles from "./styles/Home.module.css";

export default function Home() {
  const appContext = useContext(AppContext);

  return (
    <div className="container">
      <Header />
      <main className={styles.main}>
        <h1 className="title">issues</h1>

        <p className={styles.description}>
          The Powderhorn Park Neighborhood Association (PPNA) works to enhance
          the quality of life in the Powderhorn Park Neighborhood by
          facilitating community engagement, embracing a diversity of voices,
          and fostering economic and community development.
        </p>
      </main>
      {appContext.loading && <Loading />}

      <div className={styles.issues}>
        <div className={styles.grid}>
          {appContext.sheet &&
            appContext.sheet.map((issue, index) => {
              if (issue.is_visible === "TRUE") {
                return (
                  <div className={styles.card} key={issue.issue}>
                    <h3>{issue.question}</h3>
                    <p>{issue.description}</p>
                    {issue.is_closed === "FALSE" && (
                      <Link to={`/form/${index + 1}`}>Take Survey</Link>
                    )}
                    {issue.are_results_visible === "TRUE" && (
                      <a
                        href={`${issue.form.replace(
                          "viewform",
                          "viewanalytics"
                        )}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        See Results
                      </a>
                    )}
                  </div>
                );
              } else {
                return false;
              }
            })}
        </div>
      </div>
    </div>
  );
}
