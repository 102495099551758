import React, { useState, useEffect } from "react";
import Papa from "papaparse";
import AppContext from "./context/AppContext";
import { HashRouter as Router, Switch, Route } from "react-router-dom";

import Home from "./Home";
import Form from "./Form";
import "./styles/App.css";

const GOOGLE_SHEET_ID = "1KRL33KRdQaZdebEwyKFfSLQ-qj_wsY2WfFhZf0xiizU";
const GOOGLE_URL = `https://docs.google.com/spreadsheets/d/${GOOGLE_SHEET_ID}/pub?output=csv`;

function App() {
  const [data, setData] = useState({ loading: true, sheet: null });

  useEffect(() => {
    Papa.parse(GOOGLE_URL, {
      download: true,
      header: true,
      complete: function (googleSheet) {
        const data = googleSheet.data;
        setData({ loading: false, sheet: data });
      },
    });
  }, []);

  return (
    <AppContext.Provider value={data}>
      <Router>
        <Switch>
          <Route path="/form/:id" component={Form} />
          <Route exact path="/" component={Home} />
        </Switch>
      </Router>
    </AppContext.Provider>
  );
}

export default App;
