import { Link } from "react-router-dom";

export default function Header() {
  return (
    <header>
      <div className="header-content">
        <Link to="/">
          <img
            src="/assets/images/logo.png"
            className="logo"
            width="250"
            alt="logo"
          />
        </Link>
      </div>
    </header>
  );
}
